import api from "@/config/api"
class AtualizaBancoController {
  async index ( req ) {
    try {
      const response = await api.post( "/atualizacaobancodados/filtro", req );
      if ( response.data.registros.length > 0 ) {
        response.data.registros.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.atbd_id;
        });
      }
      response.data.registros.total = response.data.count;
      return response.data.registros;
    } catch ( error ) {
      console.error( error || "Erro em AtualizaBancoController index." );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/atualizacaobancodados", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AtualizaBancoController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/atualizacaobancodados/${ req.atbd_id }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AtualizaBancoController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/atualizacaobancodados/${ req.ID }` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AtualizaBancoController delete." );
      throw error
    }
  }
}

export default new AtualizaBancoController()
