import api from '../config/api';
class ControleVisaoOrdemServicoController {
  async index(req) {
    const { ORSO_ORDS_ID, ORSO_EMP_ID = 1, ORSO_SERIE = 'OS' } = req;
    let response = await api.post(`/ordemservicooculista/${ORSO_ORDS_ID}/${ORSO_EMP_ID}/${ORSO_SERIE}`, req);
    return response.data;

  }
  async create(req) {

    let response = await api.post('/ordemservicooculista', req);
    return response.data;

  }
  async update(req) {
    const { ORSO_ID, ORSO_ORDS_ID, ORSO_EMP_ID = 1, ORSO_SERIE = 'OS' } = req;
    let response = await api.put(`/ordemservicooculista/${ORSO_ORDS_ID}/${ORSO_EMP_ID}/${ORSO_SERIE}/${ORSO_ID}`, req);
    return response.data;

  }
  async delete(req) {
    const { ORSO_ID, ORSO_ORDS_ID, ORSO_EMP_ID = 1, ORSO_SERIE = 'OS' } = req;
    let response = await api.delete(`/ordemservicooculista/${ORSO_ORDS_ID}/${ORSO_EMP_ID}/${ORSO_SERIE}/${ORSO_ID}`);
    return response.data;
  }
}
export default new ControleVisaoOrdemServicoController();