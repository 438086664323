import api from "@/config/api"
class CidadesController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/cidades/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CidadesController getLength." );
      throw error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/cidades/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id =  item.CID_ID;
          item.text = item.CID_DESCRICAO + ' - ' + item.EST_SIGLA;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em CidadesController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/cidades", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CidadesController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/cidades/${ req.CID_ID }/${ req.CID_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CidadesController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/cidades/${ req.ID }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CidadesController delete." );
      throw error
    }
  }
  async search ( req ) {
    try {
      const response = await api.get( `/cidades/pesquisar/${ req.FILTRO }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CidadesController search." );
      throw error
    }
  }
  async filter ( req ) {
    try {
      const response = await api.post( `/cidades/filtrocontador/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.dados.forEach( item => {
          item.id = item.CID_ID;
          item.text = item.CID_DESCRICAO;
        });
      }
      return response.data.dados || [];
    } catch ( error ) {
      console.error( error || "Erro em CidadesController filter." );
      throw error
    }
  }
}

export default new CidadesController()
