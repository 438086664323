const ReservaVeiculosRoutes = [
    {
        path: '/reservaveiculo',
        name: 'IndexAgendamentoVeiculo',
        meta: {
            requiresAuth: true,
            title: "Reserva Veiculo"
        },
        component: () => import('@/views/agendamentoVeiculos/IndexAgendamentoVeiculo.vue')
    },
    {
        path: '/reservaveiculo/financeiro',
        name: 'FinanceiroAgendamentoVeiculo',
        meta: {
            requiresAuth: true,
            title: "Projeção Financeira"
        },
        component: () => import('@/views/agendamentoVeiculos/FinanceiroAgendamentoVeiculo.vue')
    },
    {
        path: '/baita-mundo/calendario/:id',
        name: 'BaitaMundoCalendario',
        meta: {
           title: "Calendário Baita Mundo",
           hideNavbar: true
        },
        component: () => import('@/views/agendamentoVeiculos/CalendarVeiculos.vue')
    }
]

export default ReservaVeiculosRoutes
