const UsuariosRoutes = [
  {
    path: "/usuarios",
    name: "IndexUsuarios",
    meta: {
      requiresAuth: true,
      title: "Usuários"
    },
    component: () => import( "@/views/usuarios/IndexUsuarios.vue" )
  },
  {
    path: "/usuarios/inserir",
    name: "InsertUsuarios",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Usuário"
    },
    component: () => import( "@/views/usuarios/InsertUsuarios.vue" ) /* Manage */
  },
  {
    path: "/usuarios/alterar",
    name: "AlterUsuarios",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Usuário"
    },
    component: () => import( "@/views/usuarios/AlterUsuarios.vue" ) /* Manage */
  }
]

export default UsuariosRoutes
