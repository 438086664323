const ContasAReceberRoutes = [
  {
    path: "/contasareceber",
    name: "IndexContasAReceber",
    meta: {
      requiresAuth: true,
      title: "Contas a Receber"
    },
    component: () => import( "@/views/contasAReceber/IndexContasAReceber.vue" )
  },
  {
    path: "/contasareceber/inserir",
    name: "InsertContasAReceber",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Contas a Receber"
    },
    component: () => import( "@/views/contasAReceber/ManageContasAReceber.vue" )
  },
  {
    path: "/contasareceber/alterar",
    name: "AlterContasAReceber",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Contas a Receber"
    },
    component: () => import( "@/views/contasAReceber/ManageContasAReceber.vue" )
  }
]

export default ContasAReceberRoutes
 