import api from '@/config/api';
class WhatsappController {
  /* eslint-disable */
  async send ( req ) {
    try {
      let response = await api.post( `/whatsapp/send`, req );
      return response?.data;
    } catch ( error ) {
      throw error;
    }
  }
  async auth ( req ) {
    try {
      let response = await api.post( `/whatsapp/auth`, req );
      return response?.data;
    } catch ( error ) {
      throw error;
    }
  }
  async status ( number ) {
    try {
      let response = await api.get( `/whatsapp/status/${ number }` );
      return response?.data;
    } catch ( error ) {
      throw error;
    } 
  }
  async index ( req ) {
    try {
      let response = await api.get( `/whatsapp/messages`, { params: req })
      return response?.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new WhatsappController();