const ColaboradoresRoutes = [
  {
    path: "/colaboradores",
    name: "IndexColaboradores",
    meta: {
      requiresAuth: true,
      title: "Colaboradores"
    },
    component: () => import( "@/views/colaboradores/IndexColaboradores.vue" )
  },
  {
    path: "/colaboradores/inserir",
    name: "InsertColaboradores",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Colaborador"
    },
    component: () => import( "@/views/colaboradores/ManageColaboradores.vue" )
  },
  {
    path: "/colaboradores/alterar",
    name: "AlterColaboradores",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Colaborador"
    },
    component: () => import( "@/views/colaboradores/ManageColaboradores.vue" )
  }
]

export default ColaboradoresRoutes
