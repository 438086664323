import api from "@/config/api"
class CondicionaisController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/condicional/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisController getLength." );
      throw error;
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/condicional/${ req.EMP_ID }`, req );
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisController index." );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/condicional", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisController create." );
      throw error;
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/condicional/${ req.COND_ID }/${ req.COND_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisController update." );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/condicional/${ req.ID }/${ req.EMP_ID }` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisController delete." );
      throw error;
    }
  }
  async search ( req ) {
    try {
      const response = await api.post( `/condicional/${ req.FILTRO }/${ req.EMP_ID }` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisController search." );
      throw error;
    }
  }
}

export default new CondicionaisController()
