const ConcilicaoBancariaRoutes = [
    {
      path: "/conciliacaobancaria",
      name: "IndexConciliacao",
      meta: {
        requiresAuth: true,
        title: "Conciliação Bancária"
      },
      component: () => import( "@/views/conciliacaoBancaria/indexConciliacaoBancaria.vue" )
    }
  ]
  
  export default ConcilicaoBancariaRoutes;
  