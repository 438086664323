import api from '../config/api';

class OrdersController {
  async index(req) {
    try {
      let response = await api.post(`/ordemservico/1`, req);
      if (response.data.code != 500) {
        if (response.data.length > 0) {
          response?.data?.forEach((item, index) => {
            item["ORDEM"] = index % 2 === 0 ? 0 : 1;
          });
        }
        const result = response?.data || response;
        return await this.getLength(req, result);
      }
      return [];
    }
    catch {
      return [];
    }
  }
  async getLength(req, res) {
    let total = await api.post('/ordemservico/registros/1', req);
    let response = res;
    response.total = total.data.TOT_REGISTROS;
    return response;
  }

  async create(req) {
    let response = await api.post('/ordemservico/', req);
    return response.data;
  }

  async update(req) {
    let response = await api.put(`/ordemservico/${req.ORDS_ID}/1/OS`, req);
    return response.data;
  }

  async delete(req) {
    let response = await api.delete(`/ordemservico/${req.CID_ID}/1`);
    return response.data;
  }
  async baixaEstoque(req) {
    let response = await api.post("/ordemservicobaixarestoque", req);
    return response.data;
  }
  async consultaKm(req){
    const { VEIC_ID, VEIC_EMP_ID=1, VEIC_HODOMETRO=0 } = req;
    const response = await api.get(`/ordemservico/consultakm/${VEIC_ID}/${VEIC_EMP_ID}/${VEIC_HODOMETRO || '200'}`);
    return response.data;
  }

}

export default new OrdersController();