const projetoSolicitacoesRoutes = [
    {
        path: '/solicitacoes/projeto',
        name: 'IndexSolicitacoesProjeto',
        meta: {
          requiresAuth: true,
          title: "Consulta solicitações projeto",
        },
        component: () => import('@/views/solicitacoes/solicitacoesProjeto/indexProjeto.vue')
      },
      {
        path: '/solicitacoes/projeto/inserir',
        name: 'InserirSolicitacoesProjeto',
        meta: {
          requiresAuth: true,
          title: "Cadastro solicitações projeto",
        },
        component: () => import('@/views/solicitacoes/solicitacoesProjeto/insertProjeto.vue')
      },
      {
        path: '/solicitacoes/projeto/alterar',
        name: 'AlterSolicitacoesProjeto',
        meta: {
          requiresAuth: true,
          title: "Editar solicitações projeto",
        },
        component: () => import('@/views/solicitacoes/solicitacoesProjeto/alterProjeto.vue')
      }
]
export default projetoSolicitacoesRoutes;