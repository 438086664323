<template>
     <v-list-item class="nav-bar">
        <v-list-item>
          <v-img
            v-show="mini"
            src="../../../assets/images/logo-icon.png"
            max-width="30px"
            class="ml-n5 mt-n1"
            @click="$router.push({ name: 'Dashboard' })"
          ></v-img>
          <v-img
            v-show="!mini"
            src="../../../assets/images/logo_topo.png"
            max-width="115px"
            max-height="30px"
            :class="$isMobile ? 'ml-n13' : 'ml-n5'"
            @click="$router.push({ name: 'HomeView' })"
          ></v-img>
        </v-list-item>
      </v-list-item>
</template>
<script>
export default {
    props: {
    mini: Boolean,
  },
  name: "LogoSection",
}
</script>
<style scoped>

.nav-bar {
  height: 60px !important;
  width: 250px !important;
  position: absolute;
  z-index: 2;
}
</style>