const equipamentosRoutes = [
    {
        path: '/equipamento',
        name: 'IndexEquipamento',
        meta: {
            requiresAuth: true,
            title: "Equipamentos",


        },
        component: () => import('../../views/equipamentos/IndexEquipamento.vue')
    },
    {
        path: '/equipamento/inserir',
        name: 'InsertEquipamento',
        meta: {
            requiresAuth: true,
            title: "Cadastrar Equipamento",


        },
        component: () => import('../../views/equipamentos/ManageEquipamento.vue')
    },
    {
        path: '/equipamento/alterar',
        name: 'AlterEquipamento',
        meta: {
            requiresAuth: true,
            title: "Editar Equipamento",


        },
        component: () => import('../../views/equipamentos/ManageEquipamento.vue')
    },
]
export default equipamentosRoutes;