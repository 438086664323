const NumeracoesRoutes = [
  {
    path: "/numeracoes",
    name: "IndexNumeracoes",
    meta: {
      requiresAuth: true,
      title: "Numerações"
    },
    component: () => import( "@/views/numeracoes/IndexNumeracoes.vue" )
  },
  {
    path: "/numeracoes/inserir",
    name: "InsertNumeracoes",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Numeração"
    },
    component: () => import( "@/views/numeracoes/InsertNumeracoes.vue" ) /* Manage */
  },
  {
    path: "/numeracoes/alterar",
    name: "AlterNumeracoes",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Numeração"
    },
    component: () => import( "@/views/numeracoes/AlterNumeracoes.vue" ) /* Manage */
  }
]

export default NumeracoesRoutes
