const ObservacoesRoutes = [
  {
    path: "/observacoes",
    name: "IndexObservacoes",
    meta: {
      requiresAuth: true,
      title: "Observações"
    },
    component: () => import( "@/views/observacoes/IndexObservacoes.vue" )
  },
  {
    path: "/observacoes/inserir",
    name: "InsertObservacoes",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Observação"
    },
    component: () => import( "@/views/observacoes/InsertObservacoes.vue" ) /* Manage */
  },
  {
    path: "/observacoes/alterar",
    name: "AlterObservacoes",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Observação"
    },
    component: () => import( "@/views/observacoes/AlterObservacoes.vue" ) /* Manage */
  }
]

export default ObservacoesRoutes
