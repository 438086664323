const HistoricosRoutes = [
  {
    path: "/historicos",
    name: "IndexHistoricos",
    meta: {
      requiresAuth: true,
      title: "Históricos"
    },
    component: () => import( "@/views/historicos/IndexHistoricos.vue" )
  },
  {
    path: "/historicos/inserir",
    name: "InsertHistoricos",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Histórico",
    },
    component: () => import( "@/views/historicos/InsertHistoricos.vue" ) /* Manage */
  },
  {
    path: "/historicos/alterar",
    name: "AlterHistoricos",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Histórico"
    },
    component: () => import( "@/views/historicos/AlterHistoricos.vue" ) /* Manage */
  }
]

export default HistoricosRoutes
