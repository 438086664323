
import api from "@/config/api"
class ParametrosFinanceiroController {
  async index ( req ) {
    try {
      const response = await api.post( `/parametrosfinanceiro/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response?.data?.forEach(( item, index ) => {
          item[ "ORDEM" ] = index % 2 === 0 ? 0 : 1;
        });
      }
      const result = response?.data || response;
      return result;
    } catch ( error ) {
      console.error( error.msg || "Erro em ParametrosFinanceiroController index" );
      throw error;
    }
  }
}
export default new ParametrosFinanceiroController();