const CorrecoesEstoqueRoutes = [
  {
    path: "/correcoesestoque",
    name: "IndexCorrecoesEstoque",
    meta: {
      requiresAuth: true,
      title: "Correção de Estoque"
    },
    component: () => import( "@/views/correcoesEstoque/IndexCorrecoesEstoque.vue" )
  }
]

export default CorrecoesEstoqueRoutes
