const UnidadesRoutes = [
  {
    path: "/unidades",
    name: "IndexUnidades",
    meta: {
      requiresAuth: true,
      title: "Unidades"
    },
    component: () => import( "@/views/unidades/IndexUnidades.vue" )
  },
  {
    path: "/unidades/inserir",
    name: "InsertUnidades",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Unidade"
    },
    component: () => import( "@/views/unidades/ManageUnidades.vue" )
  },
  {
    path: "/unidades/alterar",
    name: "AlterUnidades",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Unidade"
    },
    component: () => import( "@/views/unidades/ManageUnidades.vue" )
  }
]

export default UnidadesRoutes
