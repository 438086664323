const naturezasOperacao = [
  {
    path: "/naturezasoperacao",
    name: "IndexNaturezasOperacao",
    meta: {
      requiresAuth: true,
      title: "Naturezas de Operação"
    },
    component: () => import( "@/views/naturezasOperacao/IndexNaturezasOperacao.vue" )
  },
  {
    path: "/naturezasoperacao/inserir",
    name: "InsertNaturezasOperacao",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Natureza de Operação"
    },
    component: () => import( "@/views/naturezasOperacao/InsertNaturezasOperacao.vue" ) /* Manage */
  },
  {
    path: "/naturezasoperacao/alterar",
    name: "AlterNaturezasOperacao",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Natureza de Operação"
    },
    component: () => import( "@/views/naturezasOperacao/AlterNaturezasOperacao.vue" ) /* Manage */
  }
]

export default naturezasOperacao
