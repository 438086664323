import api_rel from "@/config/api_rel"
class CondicionaisRelatoriosController {
  async getRelatorio ( req ) {
    try {
      const response = await api_rel.post( "/condicional/reports", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisRelatoriosController getRelatorio." );
      throw error;
    }
  }
}

export default new CondicionaisRelatoriosController()
