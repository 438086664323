import { func } from "./functions";
export const manageInputs = {
    getByPositions(req) {
        const { inputs, positions } = req;
        const response = [];
        positions.forEach(index => {
            response.push(inputs[index]);
        });
        return response;
    },
    updateItems(req) {
        const { inputs, items } = req;
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        inputs.forEach(input => {
            if (hasOwnProperty.call(items, input.value)) {
                const itemValue = Array.isArray(input.value) ? input.value[0] : input.value;
                if (input?.type?.includes("combobox")) {
                    input.selected = null;
                    input.selected = input?.data?.find(item => item.id == items[itemValue])
                }
                else if (input?.type === 'checkbox') {
                    input.data = items[itemValue] === 'S' || items[itemValue] === 1 ? true : false
                }
                else if (input?.type === 'date' || input?.mask === "##/##/####" || input?.format === 'date') {
                    input.data = func.convertDateTime(items[itemValue]);
                }
                else if (input?.mask === "##:##" || input?.format === 'time') {
                    input.data = func.convertTime(items[itemValue]);
                }
                else if (input?.type === 'table') {
                    input.data = [];
                }
                else if (input?.type === 'money3') {
                    input.data = items[itemValue];
                    input.dataFormated = func.convertMoney2(items[itemValue]);
                }

                else {
                    input.data = items[itemValue];
                }
            }
        });
        return inputs;
    },
    separateItemsCombobox(input, quantity) {
        const hyphenMatches = input?.match(/-/g);
        if (!hyphenMatches || hyphenMatches?.length < quantity - 1) {
            return null;
        }
        const parts = input.split(/\s*-\s*/);
        return { description: parts[quantity - 1] || '' };
    },
    verifyItems(req) {
        const { inputs, items } = req;
        const isMatch = inputs.some(form => {
            const itemValue = Array.isArray(form.value) ? form.value[0] : form.value;
            if (!itemValue) return false;

            let originalValue = items[itemValue] || '';
            let newValue = form?.data || '';
            if (form?.type?.includes('combobox')) {
                newValue = form?.selected?.id || '';
            } else if (form?.type === 'date' || form?.mask === '##/##/####') {
                originalValue = func.convertDateTime(originalValue);
            } else if (form?.type === 'time' || form?.mask === '##:##') {
                originalValue = originalValue || '00:00';
            }
            else if (form?.type === 'checkbox') {
                if (originalValue == 1 && newValue === true) {
                    originalValue = true;
                }
            }
            const result = originalValue?.toString()?.toUpperCase() != newValue?.toString()?.toUpperCase();
            if (result) {
                console.log(`Item Atualizado -> ${itemValue}: ${originalValue} - ${newValue}`)
            }
            return result;
        });
        return isMatch;
    },
    mountForm(req) {
        const { inputs, prefix } = req;
        const form = inputs.reduce((form, input) => {
            if (input?.value) {
                if (Array.isArray(input.value)) {
                    input.value.forEach((element) => {
                        form[element] = input?.selected ? input?.selected[element?.replace(prefix + '_', '')] : null;
                    });
                }
                else {
                    if (input?.type?.includes("combobox")) {
                        form[input.value] = input?.selected?.id || "";
                    } else if (input?.type === "checkbox") {
                        form[input.value] = input?.data ? 1 : 0;
                    } else if (input?.value.includes("_DTA_") || input?.value.includes("_DATA_") && input?.data) {
                        form[input.value] = func.convertToApiDate(input?.data);
                    } else {
                        form[input.value] = input?.data || "";
                    }
                }
            }
            return form;
        }, {});
        return form;
    },
    mask(req) {
        let { data, format, mask } = req || {};
        if (!mask) {
            const maskMap = {
                number: '#######################',
                date: '##/##/####',
                time: '##:##'
            };
            mask = maskMap[format] || '';
        }
        if (mask) {
            let resultado = "";
            let indiceValor = 0;

            for (let i = 0; i < mask.length; i++) {
                const charMask = mask[i];
                const charValor = data[indiceValor];
                if (!charValor) break;

                switch (charMask) {
                    case "#":
                        if (/[0-9]/.test(charValor)) {
                            resultado += charValor;
                            indiceValor++;
                        }
                        break;
                    default:
                        if (resultado.length > 0 && indiceValor > 0) {
                            resultado += charMask;
                        }
                        if (charValor === charMask) {
                            indiceValor++;
                        }
                }
            }
            return resultado;
        }
    }

}