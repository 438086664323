const EstadosRoutes = [
  {
    path: "/estados",
    name: "IndexEstados",
    meta: {
      requiresAuth: true,
      title: "Estados"
    },
    component: () => import( "@/views/estados/IndexEstados.vue" )
  },
  {
    path: "/estados/inserir",
    name: "InsertEstados",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Estado"
    },
    component: () => import( "@/views/estados/InsertEstados.vue" ) /* Manage */
  },
  {
    path: "/estados/alterar",
    name: "AlterEstados",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Estado"
    },
    component: () => import( "@/views/estados/AlterEstados.vue" ) /* Manage */
  }
]

export default EstadosRoutes
