const controleServicosRoutes = [
    {
        path: '/controleservicos',
        name: 'IndexControleServicos',
        meta: {
          requiresAuth: true,
          title: "Controle de Serviços",
        },
        component: () => import('@/views/controleServicos/indexControleServicos.vue')
      },
      {
        path: '/controleservicos/:acao',
        name: 'actionControleServico',
        meta: {
          requiresAuth: true,
          title: 'Controle de Serviço',
        },
        component: () => import('@/views/controleServicos/controleServico.vue'),
      },
]
export default controleServicosRoutes;