const CondicoesVencimentoRoutes = [
  {
    path: "/condicoesvencimento",
    name: "IndexCondicoesVencimento",
    meta: {
      requiresAuth: true,
      title: "Condições de Vencimento"
    },
    component: () => import( "@/views/condicoesVencimento/IndexCondicoesVencimento.vue" )
  },
  {
    path: "/condicoesvencimento/inserir",
    name: "InsertCondicoesVencimento",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Condições de Vencimento"
    },
    component: () => import( "@/views/condicoesVencimento/InsertCondicoesVencimento.vue" ) /* Manage */
  },
  {
    path: "/condicoesvencimento/alterar",
    name: "AlterCondicoesVencimento",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Condição de Vencimento"
    },
    component: () => import( "@/views/condicoesVencimento/AlterCondicoesVencimento.vue" ) /* Manage */
  }
]

export default CondicoesVencimentoRoutes
