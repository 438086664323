const CondicionalRoutes = [
  {
    path: "/condicional",
    name: "IndexCondicionais",
    meta: {
      requiresAuth: true,
      title: "Condicionais"
    },
    component: () => import( "@/views/condicionais/IndexCondicionais.vue" )
  },
  {
    path: "/condicional/inserir",
    name: "InsertCondicionais",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Condicional"
    },
    component: () => import( "@/views/condicionais/ManageCondicionais.vue" )
  },
  {
    path: "/condicional/alterar",
    name: "AlterCondicionais",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Condicional"
    },
    component: () => import( "@/views/condicionais/ManageCondicionais.vue" )
  }
]

export default CondicionalRoutes
