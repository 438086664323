const FormasPagamentoRoutes = [
  {
    path: "/formaspagamento",
    name: "IndexFormasPagamento",
    meta: {
      requiresAuth: true,
      title: "Formas de Pagamento"
    },
    component: () => import( "@/views/formasPagamento/IndexFormasPagamento.vue" )
  },
  {
    path: "/formaspagamento/inserir",
    name: "InsertFormasPagamento",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Forma de Pagamento"
    },
    component: () => import( "@/views/formasPagamento/InsertFormasPagamento.vue" ) /* Manage */
  },
  {
    path: "/formaspagamento/alterar",
    name: "AlterFormasPagamento",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Forma de Pagamento"
    },
    component: () => import( "@/views/formasPagamento/AlterFormasPagamento.vue" ) /* Manage */
  }
]

export default FormasPagamentoRoutes
