const RequisicoesRoutes = [
  {
    path: "/requisicoes",
    name: "IndexRequisicoes",
    meta: {
      requiresAuth: true,
      title: "Requisições"
    },
    component: () => import( "@/views/requisicoes/IndexRequisicoes.vue" )
  },
  {
    path: "/requisicoes/inserir",
    name: "InsertRequisicoes",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Requisição"
    },
    component: () => import( "@/views/requisicoes/ManageRequisicoes.vue" )
  },
  {
    path: "/requisicoes/alterar",
    name: "AlterRequisicoes",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Requisição"
    },
    component: () => import( "@/views/requisicoes/ManageRequisicoes.vue" )
  }
]

export default RequisicoesRoutes
