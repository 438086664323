import axios from "axios"
import store from "../store/store"

const urlBackend = process.env.VUE_APP_BACKEND

const api = axios.create({
  baseURL: urlBackend,
  timeout: 15000
});

api.interceptors.response.use( response => {
  return response
}, error => {
  if ( error.code == "ECONNABORTED" ) {
    throw { msg: "Tempo de Solicitação Esgotado", code: 408 }
  } else {
    const code = error.response?.status;
    if ( code === 401 ) {
      store.dispatch( "logout" );
      window.location.reload();
    }
    throw { msg: error?.response?.data?.msg, code }
  }
});

export default api
