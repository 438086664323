import api from "@/config/api_rel"
class DashboardController {
  async contasReceber ( req ) {
    try {
      const response = await api.post( "/dasboard/contasreceber", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController contasReceber" );
      throw error
    }
  }
  async contasPagar ( req ) {
    try {
      const response = await api.post( "/dasboard/contaspagar", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController contasPagar" );
      throw error
    }
  }
  async despesaCC ( req ) {
    try {
      const response = await api.post( "/dasboard/despesacentrocusto", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController despesaCC" );
      throw error
    }
  }
  async despesaGrupoCC ( req ) {
    try {
      const response = await api.post( "/dasboard/despesagrupocentrocusto", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController despesaGrupoCC" );
      throw error
    }
  }
  async formaPgto ( req ) {
    try {
      const response = await api.post( "/dasboard/formaspagamentos", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController formaPgto" );
      throw error
    }
  }
  async vendasGrupo ( req ) {
    try {
      const response = await api.post( "/dasboard/vendasgrupo", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController vendasGrupo" );
      throw error
    }
  }
  async vendasSubGrupo ( req ) {
    try {
      const response = await api.post( "/dasboard/vendassubgrupo", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController vendasSubGrupo" );
      throw error
    }
  }
  async vendasSetor ( req ) {
    try {
      const response = await api.post( "/dasboard/vendassetor", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro em DashboardController vendasSetor" );
      throw error
    }
  }
}
export default new DashboardController();