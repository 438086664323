const ProgramasRoutes = [
  {
    path: "/programas",
    name: "IndexProgramas",
    meta: {
      requiresAuth: true,
      title: "Programas"
    },
    component: () => import( "@/views/programas/IndexProgramas.vue" )
  },
  {
    path: "/programas/inserir",
    name: "InsertProgramas",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Programa"
    },
    component: () => import( "@/views/programas/InsertProgramas.vue" ) /* Manage */
  },
  {
    path: "/programas/alterar",
    name: "AlterProgramas",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Programa"
    },
    component: () => import( "@/views/programas/AlterProgramas.vue" ) /* Manage */
  }
]

export default ProgramasRoutes
