const modeloEquipamentoRoutes = [
    {
            path: '/modeloequipamento',
            name: 'IndexModeloEquipamento',
            meta: {
              requiresAuth: true,
              title: "Modelo Equipamento",
        
        
            },
            component: () => import('../../views/modeloEquipamentos/IndexModeloEquipamento.vue')
          },
        
        
          {
            path: '/modeloequipamento/inserir',
            name: 'InsertModeloEquipamento',
            meta: {
              requiresAuth: true,
              title: "Inserir Modelo Equipamento",
        
        
            },
            component: () => import('../../views/modeloEquipamentos/ManageModeloEquipamento.vue')
          },
          {
            path: '/modeloequipamento/alterar',
            name: 'AlterModeloEquipamento',
            meta: {
              requiresAuth: true,
              title: "Editar Modelo Equipamento",
        
        
            },
            component: () => import('../../views/modeloEquipamentos/ManageModeloEquipamento.vue')
          },
]
export default modeloEquipamentoRoutes;