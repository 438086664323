const EmpresasRoutes = [
  {
    path: "/empresas",
    name: "IndexEmpresas",
    meta: {
      requiresAuth: true,
      title: "Empresas"
    },
    component: () => import( "@/views/empresas/IndexEmpresas.vue" )
  },
  {
    path: "/empresas/inserir",
    name: "InsertEmpresas",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Empresa"
    },
    component: () => import( "@/views/empresas/InsertEmpresas.vue" ) /* Manage */
  },
  {
    path: "/empresas/alterar",
    name: "AlterEmpresas",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Empresa"
    },
    component: () => import( "@/views/empresas/AlterEmpresas.vue" ) /* Manage */
  }
]

export default EmpresasRoutes
