const FornecedoresRoutes = [
  {
    path: "/fornecedores",
    name: "IndexFornecedores",
    meta: {
      requiresAuth: true,
      title: "Fornecedores"
    },
    component: () => import( "@/views/fornecedores/IndexFornecedores.vue" )
  },
  {
    path: "/fornecedores/inserir",
    name: "InsertFornecedores",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Fornecedor"
    },
    component: () => import( "@/views/fornecedores/InsertFornecedores.vue" ) /* Manage */
  },
  {
    path: "/fornecedores/alterar",
    name: "AlterFornecedores",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Fornecedor"
    },
    component: () => import( "@/views/fornecedores/AlterFornecedores.vue" ) /* Manage */
  }
]

export default FornecedoresRoutes
