const homeRoutes = [
  {
    path: '/',
    name: 'HomeView',
    meta: {
      requiresAuth: true,
      title: "Página Inicial",
    },
    component: () => import( "@/views/HomeView.vue" )
  }
]
export default homeRoutes;
