import api from "@/config/api"
class GerenciadorEmpresasController {
  async index ( req ) {
    try {
      const response = await api.post( "/empresa/filtro", req );
      if ( response.data.code != 500 ) {
        response.data.total = response.data[ response.data.length - 1 ];
        delete response.data[ response.data.length - 1 ];
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.text = item.empdescricao;
        });
      }
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em GerenciadorEmpresasController index." );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/empresa", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em GerenciadorEmpresasController create." );
      throw error;
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/empresa/${ req.id }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em GerenciadorEmpresasController update." );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/empresa/${ req.ID }` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em GerenciadorEmpresasController delete." );
      throw error;
    }
  }
  async search ( req ) {
    try {
      const response = await api.get( `empresa/${ req.FILTRO }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em GerenciadorEmpresasController search." );
      throw error;
    }
  }
}

export default new GerenciadorEmpresasController()
