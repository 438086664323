import api from "@/config/api"
class GradeProdutosController {
  async filtro ( req ) {
    try {
      // req = {
      //    TIPO_CONS: "Barra" ou "COD",
      //    ID: codigo de barras do produto ou id
      // };
      const response = await api.post( `/produtosgradevendas/${ req.EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em GradeProdutosController filtro." );
      throw error;
    }
  }
}

export default new GradeProdutosController()
