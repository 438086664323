const GruposHistoricoPadrao = [
  {
    path: "/gruposhistoricopadrao",
    name: "IndexGruposHistoricoPadrao",
    meta: {
      requiresAuth: true,
      title: "Grupos Histórico Padrão"
    },
    component: () => import( "@/views/gruposHistoricoPadrao/IndexGruposHistoricoPadrao.vue" )
  },
  {
    path: "/gruposhistoricopadrao/inserir",
    name: "InsertGruposHistoricoPadrao",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Grupo Histórico Padrão"
    },
    component: () => import( "@/views/gruposHistoricoPadrao/InsertGruposHistoricoPadrao.vue" ) /* Manage */
  },
  {
    path: "/gruposhistoricopadrao/alterar",
    name: "AlterGruposHistoricoPadrao",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Grupo Histórico Padrao"
    },
    component: () => import( "@/views/gruposHistoricoPadrao/AlterGruposHistoricoPadrao.vue" ) /* Manage */
  }
]

export default GruposHistoricoPadrao
