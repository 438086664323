const solicitacoesRoutes = [
    {
        path: '/solicitacoes',
        name: 'IndexSolicitacoes',
        meta: {
          requiresAuth: true,
          title: "Solicitações",
        },
        component: () => import('@/views/solicitacoes/IndexSolicitacoes.vue')
      },
      {
        path: '/solicitacoes/inserir',
        name: 'InsertSolicitacoes',
        meta: {
          requiresAuth: true,
          title: "Cadastrar de solicitações",
        },
        component: () => import('@/views/solicitacoes/InsertSolicitacoes.vue')
      },
      {
        path: '/solicitacoes/alterar',
        name: 'AlterSolicitacoes',
        meta: {
          requiresAuth: true,
          title: "Editar solicitação",
        },
        component: () => import('@/views/solicitacoes/AlterSolicitacoes.vue')
      },
      {
        path: '/solicitacoes/agenda',
        name: 'IndexCalendario',
        meta: {
          requiresAuth: true,
          title: "Agenda",
        },
        component: () => import('@/views/solicitacoes/calendario/IndexCalendario.vue')
      }
    
    ]
export default solicitacoesRoutes;
