const GerenciadorEmpresasRoutes = [
  {
    path: "/gerenciadorempresas",
    name: "IndexGerenciadorEmpresas",
    meta: {
      requiresAuth: true,
      title: "Empresas"
    },
    component: () => import( "@/views/projetos/gerenciadorEmpresas/IndexEmpresas.vue" )
  },
  {
    path: "/gerenciadorempresas/inserir",
    name: "InsertGerenciadorEmpresas",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Empresa"
    },
    component: () => import( "@/views/projetos/gerenciadorEmpresas/ManageEmpresas.vue" )
  },
  {
    path: "/gerenciadorempresas/alterar",
    name: "AlterGerenciadorEmpresas",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Empresa"
    },
    component: () => import( "@/views/projetos/gerenciadorEmpresas/ManageEmpresas.vue" )
  }
]
 
export default GerenciadorEmpresasRoutes
