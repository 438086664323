<template>
  <v-app-bar dark class="white" app height="55px">
    <v-app-bar-nav-icon
    color="primary"
    @click="$emit('openMenu')"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="primary--text font-weight-bold title" >
      {{ titlePage }}
    </v-toolbar-title>
    <v-flex class="text-right buttons" cols="4">
      <v-tooltip
      left
      color="primary"
      v-if="$route.name?.toLocaleLowerCase()?.includes('index')"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"
          small
          icon
          color="primary"
          class="button-add-view"
          :class="$isMobile ? '' : 'mr-6'"
          @click="$emit('addFavourite')"
          >
            <v-icon>
              {{ $route.meta.FAV ? "mdi-star" : "mdi-star-outline" }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $route.meta.FAV ? "Remover dos Favoritos" : "Adicionar aos favoritos" }}
        </span>
      </v-tooltip>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-bind="attrs"
                v-on="on"
                small
                icon
                color="primary"
                class="button-add-view"
                :class="$isMobile ? '' : 'mr-6'"
                >
                  <v-icon>{{ "mdi-bell" }} </v-icon>
                  <div class="contador" v-if="notificacoesCount > 0">
                    {{ notificacoesCount }}
                  </div>
                </v-btn>
              </template>
              <span>{{ "Notificações" }}</span>
            </v-tooltip>
          </v-btn>
        </template>
        <!--dialog false-->
        <v-card>
          <v-list-item v-if="needUpdate" @click="forceRefreshPage">Nova Versão disponível! Clique aqui para atualizar o sistema!</v-list-item>
          <v-list-item v-for="(notification, not) in notifications" :key="not">
            {{ notification }}
          </v-list-item>
        </v-card>
      </v-menu>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="35">
              <img src="../../../assets/images/users/perfil-de-usuario.png" />
            </v-avatar>
          </v-btn>
        </template>
        <!--dialog false-->
        <v-card>
          <v-list-item-content dense class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar>
                <img src="../../../assets/images/users/perfil-de-usuario.png" />
              </v-avatar>
              <h3 class="mt-4 mb-2">{{ user }}</h3>
              <h6 class="mb-2">{{ empresaCNPJ }}</h6>
              <h6 class="mt-4 mb-4">Versão: {{ version }}</h6>
              <v-divider></v-divider>
              <v-list-item dense @click="caminhoWhatsapp()" class="justify-center">
                <h5><v-icon>mdi-whatsapp</v-icon> WHATSAPP </h5>
              </v-list-item>
              <v-list-item v-if="id == 130 || id == 9999" dense @click="caminhoChamado()" class="justify-center">
                <h5><v-icon>mdi-face-agent</v-icon> HELP DESK </h5>
              </v-list-item>
              <v-list-item v-if="id == 130 || id == 9999" dense @click="caminhoAtualizaBanco()" class="justify-center">
                <h5><v-icon>mdi-database-cog</v-icon> ATUALIZA BD</h5>
              </v-list-item>
              <v-list-item v-if="id == 130 || id == 9999" dense @click="caminhoEmpresas()" class="justify-center">
                <h5><v-icon>mdi-domain</v-icon> EMPRESAS</h5>
              </v-list-item>
              <v-btn block height="50px" class="mb-n3" color="red" text @click="sair()">
                Sair
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-flex>
  </v-app-bar>
</template>
<script>
const env = require("@/config/env.json");
export default {
  props: {
    drawer: Boolean,
    user: String,
    empresaCNPJ: String,
  },
  data: () => ({
    title: "BSSOFT",
    favourite: false,
    items: [],
    menuBackup: [],
    version: env.version,
    notifications: [],
    notificacoesCount: 0,
    needUpdate: false,
    notificado: false,
    versionServ: null
  }),
  async created() {
    this.PROG_NOME = this.$store.state.empresa;
    this.id = this.$cript.decryptData(this.$store.state.id);
    await this.initializeNotifications();
  },
  computed: {
    titlePage() {
      if (this.$route.params?.acao == "alterar") {
        return `Editar ${this.$route.meta.title}`;
      }
      if (this.$route.params?.acao == "inserir") {
        return `Cadastrar ${this.$route.meta.title}`;
      }
      return this.$route.meta.title;
    },
  },
  methods: {
    forceRefreshPage() {
      location.reload(true);
    },
    formatarVersao(version) {
      return parseInt(version.replace(/\s*beta\s*/gi, "").replace(/\./g, ""));
    },
    initializeNotifications() {
      this.$socket.emit("entrarSala", {
        user: this.user,
        empresa: this.id,
        version: this.version,
      });
      this.$socket.on("respostaVersao", (req) => {
        let { versaoServ } = req;
        const versaoServidor = this.formatarVersao(versaoServ);
        const versaoLocal = this.formatarVersao(this.version);
        this.$nextTick(() => {
          this.needUpdate = versaoServidor > versaoLocal;
        });
        if(this.needUpdate && !this.notificado){
          this.notificacoesCount = this.notifications.length +  1
          this.notificado = true;
          this.$snotify.warning('Nova versão disponível atualize o sistema!')
        }
      });
      this.$socket.on("novaNotificacao", (req) => {
        const { msg } = req;
        this.notifications.push(msg);
        this.notificacoesCount += 1;
      });
    },
    caminhoWhatsapp() {
      this.$router.push({ name: "IndexWhatsapp" });
    },
    caminhoChamado() {
      this.$router.push({ name: "IndexSolicitacoes" });
    },
    caminhoAtualizaBanco() {
      this.$router.push({ name: "IndexAtualizaBanco" });
    },
    caminhoEmpresas() {
      this.$router.push({ name: "IndexGerenciadorEmpresas" });
    },
    clearCache() {
      sessionStorage.clear();
    },
    sair() {
      this.$store.dispatch("logout");
      this.clearCache();
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.contador {
  position: absolute;
  top: 0px;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px 2px 2px 2px;
  height: 12px;
  font-size: 9px;
}
.title {
  margin-right: 20px;
}
.buttons {
  display: flex;
  justify-content: flex-end;  /* ou qualquer outra propriedade de alinhamento que você preferir */
  align-items: center;
}
</style>