const CoresRoutes = [
  {
    path: "/cores",
    name: "IndexCores",
    meta: {
      requiresAuth: true,
      title: "Cores"
    },
    component: () => import( "@/views/cores/IndexCores.vue" )
  },
  {
    path: "/cores/inserir",
    name: "InsertCores",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Cor"
    },
    component: () => import( "@/views/cores/InsertCores.vue" ) /* Manage */
  },
  {
    path: "/cores/alterar",
    name: "AlterCores",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Cor"
    },
    component: () => import( "@/views/cores/AlterCores.vue")
  }
]

export default CoresRoutes
