const CidadesRoutes = [
  {
    path: "/cidades",
    name: "IndexCidades",
    meta: {
      requiresAuth: true,
      title: "Cidades"
    },
    component: () => import( "@/views/cidades/IndexCidades.vue" )
  },
  {
    path: "/cidades/inserir",
    name: "InsertCidades",
    meta: {
      requiresAuth: true,
      title: "Cadastrar Cidade"
    },
    component: () => import( "@/views/cidades/ManageCidades.vue" )
  },
  {
    path: "/cidades/alterar",
    name: "AlterCidades",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Cidade"
    },
    component: () => import( "@/views/cidades/ManageCidades.vue" )
  }
]

export default CidadesRoutes
