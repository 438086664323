import api from "@/config/api"
class CondicionaisProdutosController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/condicionalprod/registros/${ req.COND_ID }/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController getLength." );
      throw error;
    }
  }
  async index ( req ) {
    try {
      const response = await api.get( `/condicionalprod/${ req.COND_ID }/${ req.EMP_ID }`, req );
      return await this.getLength( req, response?.data );
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController index." );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/condicionalprod", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController create." );
      throw error;
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/condicionalprod/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDP_ID }/${ req.PROD_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController update." );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete(`/condicionalprod/${req.COND_ID}/${req.COND_EMP_ID}/${req.CNDP_ID}/${req.PROD_ID}`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController delete." );
      throw error;
    }
  }
}

export default new CondicionaisProdutosController()
