const ConsultaProdutosRoutes = [
  {
    path: "/consultaprodutos",
    name: "IndexConsultaProdutos",
    meta: {
      requiresAuth: true,
      title: "Consulta Produtos"
    },
    component: () => import( "@/views/consultaProdutos/IndexConsultaProdutos.vue" )
  },
  {
    path: "/consultaprodutos/alterar",
    name: "AlterConsultaProdutos",
    meta: {
      requiresAuth: true,
      title: "Histórico de Estoque"
    },
    component: () => import( "@/views/consultaProdutos/AlterConsultaProdutos.vue" )
  }
]

export default ConsultaProdutosRoutes
