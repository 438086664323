const marcaEquipamentoRoutes = [
    {
            path: '/marcaequipamento',
            name: 'IndexMarcaEquipamento',
            meta: {
              requiresAuth: true,
              title: "Marca Equipamento",
        
        
            },
            component: () => import('../../views/marcaEquipamentos/IndexMarcaEquipamento.vue')
          },
        
          {
            path: '/marcaequipamento/inserir',
            name: 'InsertMarcaEquipamento',
            meta: {
              requiresAuth: true,
              title: "Inserir Marca Equipamento",
        
        
            },
            component: () => import('../../views/marcaEquipamentos/ManageMarcaEquipamento.vue')
          },
          {
            path: '/marcaequipamento/alterar',
            name: 'AlterMarcaEquipamento',
            meta: {
              requiresAuth: true,
              title: "Editar Marca Equipamento",
        
        
            },
            component: () => import('../../views/marcaEquipamentos/ManageMarcaEquipamento.vue')
          },
]
export default marcaEquipamentoRoutes;