const FuncoesRoutes = [
  {
    path: "/funcoes",
    name: "IndexFuncoes",
    meta: {
      requiresAuth: true,
      title: "Funções"
    },
    component: () => import( "@/views/funcoes/IndexFuncoes.vue" )
  },
  {
    path: "/funcoes/inserir",
    name: "InsertFuncoes",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Funções"
    },
    component: () => import( "@/views/funcoes/ManageFuncoes.vue" )
  },
  {
    path: "/funcoes/alterar",
    name: "AlterFuncoes",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Função"
    },
    component: () => import( "@/views/funcoes/ManageFuncoes.vue" )
  }
]

export default FuncoesRoutes
