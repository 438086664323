
import api from "@/config/api"
class ParametrosGenericosController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( "/parametrosgenerico/registros", req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error.msg || "Erro ParametrosGenericosController - getLength" );
      throw error;
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( "/parametrosgenerico/filtros", req );
      if ( response.data.msg ) {
        response?.data?.forEach(( item, index ) => {
          item[ "ORDEM" ] = index % 2 === 0 ? 0 : 1;
        });
      }
      const result = response?.data || response;
      return await this.getLength( req, result );
    } catch ( error ) {
      console.error( error.msg || "Erro ParametrosGenericosController - index" );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/parametrosgenerico", req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro ParametrosGenericosController - create" );
      throw error;
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/parametrosgenerico/${ req.PARG_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro ParametrosGenericosController - update" );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/parametrosgenerico/${ req.PARG_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro ParametrosGenericosController - delete" );
      throw error;
    }
  }
  async search ( req ) {
    try {
      const response = await api.post( `/parametrosgenerico/${ req.PARG_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error.msg || "Erro ParametrosGenericosController - search" );
      throw error;
    }
  }
}
export default new ParametrosGenericosController();