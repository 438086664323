import api from "@/config/api"
class CondicionaisServicosController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/condicionalserv/registros/${ req.COND_ID }/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisServicosController getLength." );
      throw error;
    }
  }
  async index ( req ) {
    try {
      const response = await api.get( `/condicionalserv/${ req.COND_ID }/${ req.EMP_ID }`, req );
      return await this.getLength(req, response?.data);
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisServicosController index." );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/condicionalserv", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisServicosController create." );
      throw error;
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/condicionalserv/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDI_ID }/${ req.SERV_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisServicosController update." );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/condicionalserv/${ req.COND_ID }/${ req.COND_EMP_ID }/${ req.CNDI_ID }/${ req.SERV_ID }` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisServicosController delete." );
      throw error;
    }
  }
}

export default new CondicionaisServicosController()
