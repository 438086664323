const NCMRoutes = [
  {
    path: "/ncm",
    name: "IndexNCM",
    meta: {
      requiresAuth: true,
      title: "NCM"
    },
    component: () => import( "@/views/ncm/IndexNcm.vue" )
  },
  {
    path: "/ncm/inserir",
    name: "InsertNCM",
    meta: {
      requiresAuth: true,
      title: "Cadastro de NCM"
    },
    component: () => import( "@/views/ncm/InsertNcm.vue" ) /* Manage */
  },
  {
    path: "/ncm/alterar",
    name: "AlterNCM",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de NCM"
    },
    component: () => import( "@/views/ncm/AlterNcm.vue" ) /* Manage */
  }
]

export default NCMRoutes
