const AtualizaBancoRoutes = [
  {
    path: "/atualizabanco",
    name: "IndexAtualizaBanco",
    meta: {
      requiresAuth: true,
      title: "Atualizações ao Banco de Dados"
    },
    component: () => import( "@/views/projetos/atualizabanco/IndexAtualizaBanco.vue" )
  },
  {
    path: "/atualizabanco/inserir",
    name: "InsertAtualizaBanco",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Atualização ao Banco de Dados"
    },
    component: () => import( "@/views/projetos/atualizabanco/ManageAtualizaBanco.vue" )
  },
  {
    path: "/atualizabanco/alterar",
    name: "AlterAtualizaBanco",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Atualização ao Banco de Dados"
    },
    component: () => import( "@/views/projetos/atualizabanco/ManageAtualizaBanco.vue" )
  }
]

export default AtualizaBancoRoutes
 