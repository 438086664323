import api from '../config/api';

class ModeloEquipamentosController {

  async getLength(req, res){
    let total = await api.post('/modeloequipamento/registros/1', req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }

  async index(req) {
    let response = await api.post(`/modeloequipamento/1`, req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.MDEQ_DESCRICAO;
        item.id = item.MDEQ_ID;
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }

  async create(req) {
  const response = await api.post(`/modeloequipamento`, req);
  return response.data;
  }

  async update(req) {
      const response = await api.put(`/modeloequipamento/${req.id}/${req.MDEQ_EMP_ID}`, req);
      return response.data;
  }

  async delete(req) {
    const response = await api.delete(`/modeloequipamento/${req.ID}/${req.EMP_ID}`);
    return response.data;
  }
}

export default new ModeloEquipamentosController();