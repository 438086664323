const BancosRoutes = [
  {
    path: "/bancos",
    name: "IndexBancos",
    meta: {
      requiresAuth: true,
      title: "Bancos"
    },
    component: () => import( "@/views/bancos/IndexBancos.vue" )
  },
  {
    path: "/bancos/inserir",
    name: "InsertBancos",
    meta: {
      requiresAuth: true,
      title: "Cadastrar Banco"
    },
    component: () => import( "@/views/bancos/ManageBancos.vue" )
  },
  {
    path: "/bancos/alterar",
    name: "AlterBancos",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Banco"
    },
    component: () => import( "@/views/bancos/ManageBancos.vue" )
  }
]

export default BancosRoutes
