import api from '../config/api';

class AgendaServicoController {
  async create(req) {
    let response = await api.post(`/agendaservico`, req);
    return response?.data || response;
  }
  async delete(req) {
    let response = await api.delete(`/agendaservico/${req.AGNV_ID}/${req.EMP_ID}`, req);
    return response.data || response;
  } 
}
export default new AgendaServicoController();