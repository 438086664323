const barrisRoutes = [
  {
    path: '/barris/inserir',
    name: 'InsertBarris',
    meta: {
      requiresAuth: true,
      title: "Cadastro de barris",
    },
    component: () => import('@/views/projetos/gauchinho/barris/InsertBarris.vue')
  },
  {
    path: '/barris/alterar',
    name: 'AlterBarris',
    meta: {
      requiresAuth: true,
      title: "Editar barril",
    },
    component: () => import('@/views/projetos/gauchinho/barris/AlterBarris.vue')
  },
  {
    path: '/barris',
    name: 'IndexBarris',
    meta: {
      requiresAuth: true,
      title: "Barris",
    },
    component: () => import('@/views/projetos/gauchinho/barris/IndexBarris.vue')
  }
]
export default barrisRoutes;