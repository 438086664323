const TributacoesRoutes = [
  {
    path: "/tributacoes",
    name: "IndexTributacoes",
    meta: {
      requiresAuth: true,
      title: "ICMS"
    },
    component: () => import( "@/views/tributacoes/IndexTributacoes.vue" )
  },
  {
    path: "/tributacoes/inserir",
    name: "InsertTributacoes",
    meta: {
      requiresAuth: true,
      title: "Cadastro de ICMS"
    },
    component: () => import( "@/views/tributacoes/ManageTributacoes.vue" )
  },
  {
    path: "/tributacoes/alterar",
    name: "AlterTributacoes",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de ICMS",
    },
    component: () => import( "@/views/tributacoes/ManageTributacoes.vue" )
  }
]

export default TributacoesRoutes
