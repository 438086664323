const setorSolicitacoesRoutes = [
    {
        path: '/solicitacao/setor',
        name: 'IndexSetorSolicitacao',
        meta: {
          requiresAuth: true,
          title: "Setor de solicitações",
        },
        component: () => import('@/views/solicitacoes/setorSolicitacao/IndexSetorSolicitacao.vue')
      },
      {
        path: '/solicitacao/setor/inserir',
        name: 'InsertSetorSolicitacao',
        meta: {
          requiresAuth: true,
          title: "Cadastrar setor de solicitações",
        },
        component: () => import( "@/views/solicitacoes/setorSolicitacao/InsertSetorSolicitacao.vue" )
      },
      {
        path: '/solicitacao/setor/alterar',
        name: 'AlterSetorSolicitacao',
        meta: {
          requiresAuth: true,
          title: "Editar setor de solicitações",
        },
        component: () => import('@/views/solicitacoes/setorSolicitacao/AlterSetorSolicitacao.vue')
      },
]
export default setorSolicitacoesRoutes;