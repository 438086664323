import api from "@/config/api"
class ColaboradoresController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/colaborador/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em ColaboradoresController getLength." );
      throw error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/colaborador/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.COLA_ID;
          item.text = item.COLA_NOME;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em ColaboradoresController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/colaborador", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ColaboradoresController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/colaborador/${ req.COLA_ID }/${ req.COLA_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ColaboradoresController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/colaborador/${ req.ID }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ColaboradoresController delete." );
      throw error
    }
  }
  async search ( req ) {
    try {
      const response = await api.get( `/colaborador/pesquisar/${ req.FILTRO }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ColaboradoresController search." );
      throw error
    }
  }
}

export default new ColaboradoresController()
