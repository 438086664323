const TamanhosRoutes = [
  {
    path: "/tamanhos",
    name: "IndexTamanhos",
    meta: {
      requiresAuth: true,
      title: "Tamanhos"
    },
    component: () => import( "@/views/tamanhos/IndexTamanhos.vue" )
  },
  {
    path: "/tamanhos/inserir",
    name: "InsertTamanhos",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Tamanho"
    },
    component: () => import( "@/views/tamanhos/InsertTamanhos.vue" ) /* Manage */
  },
  {
    path: "/tamanhos/alterar",
    name: "AlterTamanhos",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Tamanho",
    },
    component: () => import( "@/views/tamanhos/AlterTamanhos.vue" ) /* Manage */
  }
]

export default TamanhosRoutes
