const carregamentoBarrisRoutes = [
  {
    path: '/carregamentobarris/inserir',
    name: 'InsertCarregamentoBarris',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Carregamento de Barris",
    },
    component: () => import('@/views/projetos/gauchinho/carregamentoBarris/InsertCarregamentoBarris.vue')
  },
  {
    path: '/carregamentobarris/alterar',
    name: 'AlterCarregamentoBarris',
    meta: {
      requiresAuth: true,
      title: "Editar Carregamento de Barril",
    },
    component: () => import('@/views/projetos/gauchinho/carregamentoBarris/AlterCarregamentoBarris.vue')
  },
  {
    path: '/carregamentobarris',
    name: 'IndexCarregamentoBarris',
    meta: {
      requiresAuth: true,
      title: "Carregamento de Barris",
    },
    component: () => import('@/views/projetos/gauchinho/carregamentoBarris/IndexCarregamentoBarris.vue')
  }
]
export default carregamentoBarrisRoutes;