export default {
  actionComponent ( req ) {
    const { context, form, response, name } = req;
    context.$snotify.success( response.msg );
    const keys = Object.keys( form );
    const firstKey = keys[ 0 ];
    const prefix = firstKey.split( "_" )[ 0 ];
    form[ `${ prefix }_ID` ] = response.id;
    if ( context.isModalComponent ) {
      context.$emit( "actionComponent", "resultComponent", form );
      return
    }
    if ( context.action == "create" ) {
      context.$router.push({ name: `Alter${ name }`, params: { items: form }});
    } else {
      context.$route.params.items = { ...context.$route.params.items, ...form };
    }
  }
}
